import { apiClient } from "@/services";

const clearMessages = (cb) => {
  setTimeout(function () {
    cb("CLEAR_MESSAGES");
  }, 3000);
};

const state = {
  events: [],
  images: [],
  socials: [],
  contact: null,
  hero: null,
  about: null,
  logo: null,
  settings:null,
  error: "",
  message: "",
};
const mutations = {
  GET_SOCIALS: (state, payload) => {
    state.socials = payload;
  },
  ADD_SOCIALS: (state, payload) => {
    state.socials.push(payload);
  },
  DELETE_ICON: (state, payload) => {
    state.message = "Deleted successfully";
    state.socials = state.socials.filter((s) => s.id !== payload);
  },
  MESSAGE_SUCCESS: (state, payload) => (state.message = payload),
  MESSAGE_ERROR: (state, payload) => (state.error = payload),
  CLEAR_MESSAGES: (state) => {
    state.error = "";
    state.message = "";
  },
};

const actions = {
  async getSocials({ commit }) {
    try {
      const res = await apiClient.get("/socials");
      commit("GET_SOCIALS", res.data.socials);
      clearMessages(commit);
    } catch (error) {
      if (error.response) {
        commit("MESSAGE_ERROR", error.response.data.errorMessage);
      } else {
        commit("MESSAGE_ERROR", "Something went wrong");
      }
      clearMessages(commit);
    }
  },

  // Add Social icon
  async addSocial({ commit }, payload) {
    try {
      const res = await apiClient.post("/socials", payload);
      commit("ADD_SOCIALS", res.data.social);
      commit("MESSAGE_SUCCESS", "Admin Added Successfully");
      clearMessages(commit);
    } catch (error) {
      if (error.response) {
        commit("MESSAGE_ERROR", error.response.data.errorMessage);
      } else {
        commit("MESSAGE_ERROR", "Something went wrong");
      }
      clearMessages(commit);
    }
  },

  async deleteIcon({ commit }, id) {
    try {
      await apiClient.delete(`/socials/${id}`);
      commit("DELETE_ICON", id);
      commit("MESSAGE_SUCCESS", "Updated Successfully");
      clearMessages(commit);
    } catch (error) {
      if (error.response) {
        commit("MESSAGE_ERROR", error.response.data.errorMessage);
      } else {
        commit("MESSAGE", "Something went wrong");
      }
      clearMessages(commit);
    }
  },
  async updateIcon({ commit }, payload) {
    try {
      await apiClient.put(`/socials/${payload.id}`, payload);
      clearMessages(commit);
    } catch (error) {
      if (error.response) {
        commit("MESSAGE_ERROR", error.response.data.errorMessage);
      } else {
        commit("MESSAGE", "Something went wrong");
      }
      clearMessages(commit);
    }
  },
};

// Getters
const getters = {
  allMessages(state) {
    return {
      errorMessage: state.error || "",
      successMessage: state.message || "",
    };
  },
  getSocialIcons(state) {
    return state.socials;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
