<template>
  <div>
    <v-card height="100vh" flat>
      <v-img
        :src="`uploads/${heroImage}`"
        gradient="to top right, rgba(255,255,225,.3), rgba(25,32,72,.1)"
        height="100vh"
        width="100%"
        cover
      >
        <v-row justify="center" class="mt-16 pt-10">
          <h1
            :contenteditable="isLoggedIn"
            ref="heroTitle"
            class="herotitle text-center mt-16 pt-16"
            :style="{ color: titleFontColor }"
          >
            {{ dform.heroTitle }}
          </h1>
        </v-row>
        <v-row justify="center">
          <div class="text-center">
            <span class="d-flex">
              <b
                class="herosubtitle"
                :style="{ color: subtitleFontColor }"
                :contenteditable="isLoggedIn"
                ref="heroSubTitle"
                >{{ dform.heroSubTitle }}</b
              >
            </span>
          </div>
        </v-row>
        <div v-if="isLoggedIn" class="mt-15 mr-md-15 mr-sm-6 text-end">
          <input
            id="hero-image"
            name=""
            hidden
            type="file"
            accept="image/*"
            @change="handleHeroImage"
          />
          <label for="hero-image">
            <v-icon title="Change Image" class="rounded-circle secondary pa-3"
              >mdi-camera</v-icon
            >
          </label>

          <v-btn
            class="ml-2"
            :loading="loading"
            small
            bottom
            color="accent"
            @click="handleHomeData"
          >
            <b> save </b></v-btn
          >
        </div>
      </v-img>
    </v-card>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p class="secondary--text">{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon color="secondary">mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin } from "@/mixins";
import { apiClient } from "@/services";
import { mapGetters } from "vuex";
export default {
  mixins: [snackMixin],
  props: {
    titleFontColor: {
      type: String,
    },
    subtitleFontColor: {
      type: String,
    },
  },
  data() {
    return {
      heroImage: "",
      dform: {
        heroTitle: "",
        heroSubTitle: "",
      },
    };
  },

  created() {
    apiClient
      .get("/pages/load-hero")
      .then((res) => {
        this.dform = res.data.hero;
        this.heroImage = res.data.hero.heroImage;
      })
      .catch((err) => {
        this.displayMsg(err.message, "error");
      });
  },

  computed: {
    ...mapGetters(["isLoggedIn"]),
  },

  methods: {
    async handleHomeData() {
      this.dform.heroTitle = this.$refs.heroTitle.innerText;
      this.dform.heroSubTitle = this.$refs.heroSubTitle.innerText;
      try {
        const res = await apiClient.put("/pages/hero", {
          heroTitle: this.dform.heroTitle,
          heroSubTitle: this.dform.heroSubTitle,
        });

        this.dform = res.data.hero;
        this.displayMsg("Updated successfully", "success");
      } catch (error) {
        this.displayMsg(error.message, "error");
      }
    },

    async handleHeroImage(event) {
      let data = new FormData();
      data.append("name", "file");
      data.append("file", event.target.files[0]);
      let config = { header: { "Content-Type": "multipart/form-data" } };
      try {
        const res = await apiClient.put(
          "/pages/change-hero-image",
          data,
          config
        );
        this.heroImage = res.data.heroImage;
        this.displayMsg("Updated successfully", "success");
      } catch (error) {
        this.displayMsg(error.message, "error");
      }
    },
  },
};
</script>

<style scoped>
.herotitle {
  font-size: 75px;
}
.herosubtitle {
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .herotitle {
    font-size: 50px;
  }
  .herosubtitle {
    font-size: 10px;
  }
}
</style>
