import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import goTo from "vuetify/lib/services/goto";
import auth from '@/store'

Vue.use(VueRouter);

const routes = [
    {
        path: "/home",
        alias:"/",
        name: "Home",
        component: Home,
      },
    
      {
        path: "/about",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },

      {
        path: "/login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Login.vue"),
          meta: { user: true },
      },
      {
        path: "/register-page",
        name: "RegisterPage",
        component: () =>
          import(/* webpackChunkName: "register-page" */ "../views/RegisterPage.vue"),
          meta: { user: true },
      },
      {
        path: "/admin",
        name: "Admin",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
      },
      {
        path: "/gallery",
        name: "Gallery",
        component: () =>
          import(/* webpackChunkName: "gallery" */ "../views/Gallery.vue"),
      },
      {
        path: "/contact",
        name: "Contact",
        component: () =>
          import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
      },
      {
        path: "/404",
        alias: "*",
        name: "NotFound",
        component: () =>
          import(/* webpackChunkName: "projects" */ "@/views/NotFound.vue"),
      },

];   

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      let scrollTo = 0;
  
      if (to.hash) {
        scrollTo = to.hash;
      } else if (savedPosition) {
        scrollTo = savedPosition.y;
      }
  
      return goTo(scrollTo);
    },
  
    
  });

  router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem("admin");
    const token = localStorage.getItem("token");
    if (to.matched.some(record => record.meta.requiresAuth) ) {
      if (!loggedIn && !token) {
        next({ name: "Home", query: { redirect: to.fullPath } });
      } else {
        next();
      }
    } else {
      next();
    }
  });

  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.user)) {
      if (auth.getters.isLoggedIn) {
        next("/Home");
        return;
      }
      next();
    } else {
      next();
    }
  });

  export default router;