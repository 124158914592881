import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 400,
      sm: 680,
      md: 1024,
      lg: 1500,
    },
    scrollBarWidth: 24,
  },

  theme: {
    themes: {
      light: {
        primary:"#000000",
        secondary:"#ffffff",
        error:"B71C1C",
        accent: "#424242",
        warning:'#141212',
        success:'#4CAF50',
      },
      dark: {
        primary:"#ffffff",
        secondary:"#000000",
        error:"B71C1C",
        accent: "#424242",
        warning:'#141212',
        success:'#4CAF50',
      },
  
        
    },
  },
});
