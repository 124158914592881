import axios from 'axios'
import { v4 } from 'uuid'

export const apiClient = axios.create({
  baseURL: '/api',
})

apiClient.interceptors.request.use(
  (config) => {
    config.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
      'token'
    )}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('admin')
      localStorage.removeItem('token')
      //  location.reload()
    }
    return Promise.reject(error)
  }
)

export const uuid = v4
