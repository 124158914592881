import { apiClient } from "@/services";
import router from "@/router";

const clearMessages = (cb) => {
  setTimeout(function(){
    cb("CLEAR_MESSAGES")
  },3000)
}

const state = {
  admin: JSON.parse(window.localStorage.getItem("admin")) || null,
  token: window.localStorage.getItem("token") || "",
  isLogin: true,
  error: "",
  message: "",
};

const mutations = {
  SET_ADMIN(state, payload) {
    localStorage.setItem("admin", JSON.stringify(payload.user));
    localStorage.setItem("token", payload.token);
    state.admin = payload.user;
    state.token = payload.token;
  },
  // SET_ADMIN: (state, payload) => (state.admin = payload),
  SET_LOGIN: (state, payload) => (state.login = payload),
  SET_LOGOUT () {
    localStorage.removeItem('admin');
    localStorage.removeItem('token');
   location.reload()
 },
  REGISTER_SUCCESS: (state, payload) => (state.message = payload),
  AUTH_ERROR: (state, payload) => (state.error = payload),
  CLEAR_MESSAGES: (state) => {
    state.error = "";
    state.message = "";
  },
};


const actions = {
  // setAdmin: ({ commit }, payload) => commit("SET_ADMIN", payload),
  setLogin: ({ commit }, payload) => commit("SET_LOGIN", payload),
  register: async ({ commit }, payload) => {
    try {
      await apiClient.post("/admins/register", payload);
     
      setTimeout(() => {
        router.push({ name: "Login" });
      }, 4000);
      commit("REGISTER_SUCCESS", "Registration successful");
      clearMessages(commit)
    } catch (error) {
      if(error.response.data.errorMessage) {
        commit("AUTH_ERROR", error.response.data.errorMessage);
    } else {
      commit("AUTH_ERROR", 'Something went wrong');
    }
    clearMessages(commit)
    }
  },
  
  
  login: async ({ commit }, payload) => {
    try {
      const res = await apiClient.post("/admins/login", payload);
      commit("SET_ADMIN", res.data);
      commit("REGISTER_SUCCESS", 'Login Successful');
      clearMessages(commit)
      
      setTimeout(() => {
        router.push({ name: "Home" });
      }, 3000);
      
    } catch (error) {
      if(error.response.data.errorMessage) {
          commit("AUTH_ERROR", error.response.data.errorMessage);
      } else {
          commit("AUTH_ERROR", 'Something went wrong');
      }
      clearMessages(commit)
    } 
  },

  logout ({ commit }) {
    commit('SET_LOGOUT')
  },

};

const getters = {
  isLoggedIn(state) {
    return !!state.admin && !!state.token;
  },
  isSuperAdmin(state) {
    return state.admin.role  == 'super_admin'
  },
  getMessages(state) {
    return {
      errorMessage: state.error || "",
      successMessage: state.message || "",
    };
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
