<template>
  <v-app
    :style="{ backgroundColor: color.bgColor, fontFamily: color.fontType }"
  >
    <Splash :isLoading="isLoading" :loading="loading" />
    <div v-if="!loading">
      <nav-drawer
        :bgColor="color.bgColor"
        :fontType="color.fontType"
        :logoFontColor="color.logoFontColor"
        :menuFontColor="color.menuFontColor"
        :permanent="!permanent"
        v-if="mobile"
      />
      <nav-drawer
        :bgColor="color.bgColor"
        :fontType="color.fontType"
        :logoFontColor="color.logoFontColor"
        :menuFontColor="color.menuFontColor"
        :permanent="permanent"
        v-else
      />

      <v-navigation-drawer
        v-if="settings"
        v-model="settings"
        floating
        class="mt-3 rounded-lg"
        app
        temporary
        hide-overlay
        right
        height="500"
        width="400"
      >
        <p class="text-end mr-8 mt-2">
          <v-btn x-small fab fixed @click="settings = !settings"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </p>
        <h2 class="text-center mt-5">Settings</h2>
        <div class="ml-3">
          <p>
            Font Type Settings:
            <input type="text" v-model="color.fontType" />
          </p>
          <p>
            Background Color Settings:
            <input type="color" mode="hexa" v-model="color.bgColor" />
          </p>
          <p>
            Title Color Settings:
            <input type="color" v-model="color.titleFontColor" />
          </p>
          <p>
            Subtitle Color Settings:
            <input type="color" v-model="color.subtitleFontColor" />
          </p>
          <p>
            Text Color Settings:
            <input type="color" v-model="color.textFontColor" />
          </p>
          <p>
            Menus Text Color Settings:
            <input type="color" v-model="color.menuFontColor" />
          </p>
          <p>
            Logo Text Color Settings:
            <input type="color" v-model="color.logoFontColor" />
          </p>
          <p></p>
          <p>
            Buttons Color Settings:
            <input type="color" v-model="color.buttonFontColor" />
          </p>
          <p>
            Footer Text Color Settings:
            <input type="color" v-model="color.footerFontColor" />
          </p>
        </div>

        <v-row justify="end" class="mt-4 mr-4">
          <v-btn @click="handleSettings" :loading="settingLoad">save</v-btn>
        </v-row>
      </v-navigation-drawer>

      <v-main :style="{ backgroundColor: color.bgColor }">
        <v-btn
          v-if="isLoggedIn"
          @click="settings = !settings"
          fab
          color="blue"
          class="mb-15"
          fixed
          bottom
          right
          absolute
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
        <router-view
          :titleFontColor="color.titleFontColor"
          :subtitleFontColor="color.subtitleFontColor"
          :textFontColor="color.textFontColor"
          :buttonFontColor="color.buttonFontColor"
          :bgColor="color.bgColor"
        >
        </router-view>
      </v-main>

      <v-footer app :style="{ backgroundColor: color.bgColor }">
        <v-row justify="center" class="mt-2">
          <!-- <v-switch class="pl-5"  v-model="$vuetify.theme.dark" color="success" dark>
             <template #label>
              <b>Dark Mode</b>
             </template>
           </v-switch> -->
          <v-spacer></v-spacer>
          <p class="text-center" :style="{ color: color.footerFontColor }">
            @Copyright: chinedu-clifford {{ new Date().getFullYear() }}, All
            right Reserved. | Designed By:
            <a
              href="https://kinplusgroup.com"
              class="text-decoration-underline secondary--text"
            >
              kinplus Technologies</a
            >
          </p>
          <v-spacer></v-spacer>
          <router-link
            :to="{ name: 'Login' }"
            v-if="!isLoggedIn"
            class="text-end text-decoration-none"
          >
            staff
          </router-link>
        </v-row>
      </v-footer>
      <v-snackbar v-model="snackbar" bottom :color="snackColor">
        <p class="secondary--text">{{ snackMsg }}</p>
        <template v-slot:action="{ attrs }">
          <v-btn dark v-bind="attrs" @click="snackbar = false">
            <v-icon color="secondary">mdi-close</v-icon>
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import Splash from "@/components/Splash.vue";
import { snackMixin } from "@/mixins";
import { apiClient } from "@/services";
import NavDrawer from "@/components/NavDrawer.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Splash,
    NavDrawer,
  },
  mixins: [snackMixin],
  data() {
    return {
      permanent: false,
      settings: null,
      color: {
        bgColor: "",
        fontType: "",
        titleFontColor: "",
        subtitleFontColor: "",
        menuFontColor: "",
        textFontColor: "",
        logoFontColor: "",
        footerFontColor: "",
        buttonFontColor: "",
      },
      settingLoad: false,
      drawer: null,
      isLoading: {},
      loading: true,
      clipped: true,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    ...mapGetters(["isLoggedIn"]),
  },

  watch: {
    mobile(newval) {
      if (!newval) {
        this.hideDrawer();
      }
    },
  },

  created() {
    this.loading = true;
    apiClient
      .get("/pages/load")
      .then((res) => {
        this.isLoading = res.data.pages;
        this.loading = false;
      })
      .catch((err) => {
        this.displayMsg(err.message, "error");
      });

    apiClient
      .get("/pages/load-setting")
      .then((res) => {
        this.color = res.data.setting;
        this.loading = true;
      })
      .catch((err) => {
        this.displayMsg(err.message, "error");
      });
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },

  methods: {
    async handleSettings() {
      this.settingLoad = true;
      try {
        const res = await apiClient.put("/pages/setting", {
          bgColor: this.color.bgColor,
          fontType: this.color.fontType,
          titleFontColor: this.color.titleFontColor,
          subtitleFontColor: this.color.subtitleFontColor,
          menuFontColor: this.color.menuFontColor,
          logoFontColor: this.color.logoFontColor,
          footerFontColor: this.color.footerFontColor,
          buttonFontColor: this.color.buttonFontColor,
          textFontColor: this.color.textFontColor,
        });
        this.color = res.data.setting;
        this.settingLoad = false;
        this.displayMsg("Updated successfully", "success");
      } catch (error) {
        this.displayMsg(error.message, "error");
      }
    },

    hideDrawer() {
      this.drawer = false;
    },
  },
};
</script>
