<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      :clipped="clipped"
      hide-overlay
      :permanent="permanent"
      :style="{ backgroundColor: bgColor }"
      left
    >
      <div class="pt-5 px-2">
        <v-card flat color="transparent" height="50" width="60" class="mx-auto">
          <v-img
            :src="`uploads/${logo.image}`"
            width="100%"
            height="100%"
          ></v-img>
        </v-card>
        <p v-if="isLoggedIn" class="text-center mt-2" title="Change Logo">
          <input
            id="logoImage"
            hidden
            type="file"
            accept="image/*"
            @change="handleLogoImage"
          />
          <label for="logoImage">
            <v-icon color="primary" class="rounded-circle secondary pa-1"
              >mdi-camera</v-icon
            ></label
          >
        </p>
        <h4
          ref="logTitle"
          :contenteditable="isLoggedIn"
          :style="{ color: logoFontColor }"
          class="mt-2 text-center"
        >
          {{ logo.title }}
        </h4>
        <p v-if="isLoggedIn" class="text-center mt-3">
          <v-btn x-small @click="saveLogoTitleHandler">save</v-btn>
        </p>
      </div>

      <div class="mt-16 pt-10">
        <v-list dense nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="{ name: item.link }"
            link
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-center my-2"
                :style="{ color: menuFontColor }"
              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <p v-if="isLoggedIn" class="text-center">
            <router-link
              :to="{ name: 'Admin' }"
              v-if="isSuperAdmin"
              class="text-decoration-none"
            >
              <v-card-text
                class="text-center"
                :style="{ color: menuFontColor }"
              >
                ADMINS
              </v-card-text>
            </router-link>

            <v-btn :isLoading="loading" @click="logOut">Logout</v-btn>
          </p>
        </v-list>
      </div>
      <div class="mt-13">
        <v-col
          cols="12"
          v-for="(social, index) in getSocialIcons"
          :key="index"
          align="center"
        >
          <v-card flat color="transparent" width="40">
            <div v-if="isLoggedIn" class="d-flex">
              <v-btn text color="transparent" @click="editIcon(social)" x-small>
                <v-icon color="secondary" size="15px">mdi-pencil</v-icon></v-btn
              >
              <v-btn
                text
                color="transparent"
                @click="deleteIconHandler(social.id)"
                x-small
              >
                <v-icon color="secondary" size="15px">mdi-close</v-icon></v-btn
              >
            </div>
            <a
              :href="`${social.href}`"
              target="blank"
              class="secondary--text text-decoration-none mx-auto"
            >
              <v-icon
                :title="social.title"
                :style="{ color: menuFontColor }"
                class="mx-2"
                >mdi-{{ social.icon }}</v-icon
              >
            </a>
          </v-card>
        </v-col>
        <v-dialog
          v-if="isLoggedIn"
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <p class="text-center">
              <v-btn
                title="Add Icon"
                @click="newIcon"
                class="text-center"
                color="secondary"
                v-bind="attrs"
                v-on="on"
                fab
                small
                outlined
                ><v-icon color="secondary">mdi-plus</v-icon></v-btn
              >
            </p>
          </template>
          <v-card class="px-4">
            <v-combobox
              v-model="dform.icon"
              :items="icons"
              label="icon"
            ></v-combobox>
            <v-text-field v-model="dform.title" label="title"></v-text-field>
            <v-text-field v-model="dform.href" label="href"></v-text-field>
            <v-card-actions class="justify-end">
              <v-card-actions class="justify-end">
                <v-btn text @click="addIcon" v-if="type">Save</v-btn>
                <v-btn text @click="saveChangeHandler" v-if="!type"
                  >Save Changes</v-btn
                >
              </v-card-actions>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-navigation-drawer>
    <v-btn
      color="secondary"
      v-if="$vuetify.breakpoint.smAndDown"
      @click="drawer = 'drawer'"
      class="pa-1"
      large
      tile
    >
      <v-icon size="30px" color="primary">mdi-menu</v-icon>
    </v-btn>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p class="secondary--text">{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon color="secondary">mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin } from '@/mixins'
import { mapActions, mapGetters } from 'vuex'
import { apiClient } from '@/services'

export default {
  name: 'NavDrawer',
  mixins: [snackMixin],

  props: {
    bgColor: {
      type: String,
    },
    menuFontColor: {
      type: String,
    },
    logoFontColor: {
      type: String,
    },
    permanent: {
      type: Boolean,
    },
  },

  data() {
    return {
      logo: {
        title: '',
        image: '',
      },
      icons: [
        'facebook',
        'twitter',
        'whatsapp',
        'linkedin',
        'instagram',
        'pinterest',
      ],
      social: null,
      drawer: null,
      type: true,
      dialog: false,
      isLoading: false,
      loading: false,
      clipped: true,
      items: [
        { title: 'HOME', link: 'Home' },
        { title: 'ABOUT', link: 'About' },
        { title: 'GALLERY', link: 'Gallery' },
        { title: 'CONTACT', link: 'Contact' },
      ],
      dform: this.initializeForm(),
    }
  },

  created() {
    this.getSocials()
    this.loadLogoHandler()
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'getSocialIcons', 'isSuperAdmin']),
    isValid() {
      const reqf = ['icon', 'title', 'href']
      return reqf.every((v) => !!this.dform[v])
    },
  },

  methods: {
    initializeForm() {
      return {
        icon: '',
        title: '',
        href: '',
      }
    },
    ...mapActions([
      'getSocials',
      'addSocial',
      'deleteIcon',
      'updateIcon',
      'logout',
    ]),

    addIcon() {
      this.loading = true
      this.addSocial(this.dform)
      this.loading = true
      this.dform = this.initializeForm()
      this.dialog = false
    },

    logOut() {
      this.isLoading = true
      this.logout()
      setTimeout(() => {
        this.$router.push({ name: 'Home' })
        this.isLoading = false
      }, 2000)
    },

    deleteIconHandler(id) {
      const ok = confirm(`Are you sure you want to delete data?`)
      if (ok) {
        this.deleteIcon(id)
        this.displayMsg(`Data deleted successfully`)
      }
    },

    editIcon(social) {
      this.type = false
      this.dialog = true
      this.dform = social
    },

    newIcon() {
      this.type = true
      this.dform = this.initializeForm()
      this.dialog = true
    },

    saveChangeHandler() {
      this.loading = true
      this.updateIcon(this.dform)
      this.loading = false
      this.dform = this.initializeForm()
      this.dialog = false
    },

    async loadLogoHandler() {
      try {
        const { data } = await apiClient.get('/pages/load-logo')
        this.logo.title = data.logo.logoTitle
        this.logo.image = data.logo.logoImage
      } catch (error) {
        this.displayMsg(error.message, 'error')
      }
    },

    async saveLogoTitleHandler() {
      this.logo.title = this.$refs.logTitle.innerText
      try {
        const res = await apiClient.put('/pages/logo', {
          logoTitle: this.logo.title,
        })
        this.logo.title = res.data.logoTitle
        this.displayMsg('Updated successfully', 'success')
      } catch (error) {
        this.displayMsg(error.message, 'error')
      }
    },

    async handleLogoImage(event) {
      let data = new FormData()
      data.append('name', 'file')
      data.append('file', event.target.files[0])
      try {
        const res = await apiClient.put('/pages/update-logo-image', data)
        this.logo.image = res.data.logoImage
        this.displayMsg('Updated successfully', 'success')
      } catch (error) {
        this.displayMsg(error.message, 'error')
      }
    },
  },
}
</script>
