import { apiClient } from "@/services";

const clearMessages = (cb) => {
  setTimeout(function(){
    cb("CLEAR_MESSAGES")
  },3000)
}

const state = {
  admins: [],
  admin: null,
  error: "",
  message: "",
};

const mutations = {
  ADD_ADMIN: (state, payload) => {
    state.admins.push(payload)
    state.admin = payload
  },
  GET_ADMINS: (state, payload) => (state.admins = payload),
  UPDATE_ADMIN: (state, payload) => (state.admin = payload),
  REGISTER_SUCCESS: (state, payload) => (state.message = payload),
  DELETE_ADMIN: (state, payload) => {
    state.message = 'Deleted successfully'
    state.admins = state.admins.filter((admin) => admin.id !== payload)
    
  },
  
  ADMIN_ERROR: (state, payload) => (state.error = payload),
  CLEAR_MESSAGES: (state) => {
    state.error = "";
    state.message = "";
  },
};

const actions = {
  async createAdmin({ commit }, payload) {
    try {
      const res = await apiClient.post("/admins/createAdmin", payload);
      commit("ADD_ADMIN", res.data.user);
      commit("REGISTER_SUCCESS", "Admin Created Successfully");
      clearMessages(commit)
    } catch (error) {
      if (error.response) {
        commit("ADMIN_ERROR", error.response.data.errorMessage);
      } else {
        commit("ADMIN_ERROR", "Something went wrong");
      }
      clearMessages(commit)
    }
  },

  async getAdmins({ commit }) {
    try {
      const res = await apiClient.get("/admins");
      commit("GET_ADMINS", res.data.admins);
      clearMessages(commit)

    } catch (error) {
      if (error.response) {
        commit("ADMIN_ERROR", error.response.data.errorMessage);
      } else {
        commit("ADMIN_ERROR", "Something went wrong");
      }
      clearMessages(commit)

    }
  },

  async updateAdmin({ commit }, item) {
    try {
      const res = await apiClient.put(`/admins/${item.id}`, item);
      commit("UPDATE_ADMIN", res.data.user);
      commit("REGISTER_SUCCESS", "Admin updated Successfully");
      clearMessages(commit)
    } catch (error) {
      if (error.response) {
        commit("ADMIN_ERROR", error.response.data.errorMessage);
      } else {
        commit("ADMIN_ERROR", "Something went wrong");
      }
      clearMessages(commit)
    }
  },

  async deleteAdmin({ commit }, id) {
    try {
      await apiClient.delete(`/admins/${id}`);
      commit("DELETE_ADMIN", id);
      clearMessages(commit)
    } catch (error) {
      if (error.response) {
        commit("ADMIN_ERROR", error.response.data.errorMessage);
      } else {
        commit("ADMIN_ERROR", "Something went wrong");
      }
      clearMessages(commit)
    }
  },
};

const getters = {
  getAllAdmin(state) {
    return state.admins;
  },
  showMessages(state) {
    return {
      errorMessage: state.error || "",
      successMessage: state.message || "",
    };
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
