<template>
  <div :class="{ loader: true, fadeout: !loading}" :loading="loading">
    <v-icon class="iris" color="secondary" x-large>mdi-camera-iris</v-icon>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: {
      isLoading: {
          type: Object,
      },
      loading:{
        type:Boolean,
      }
  },
};
</script>

<style>
.loader {
  background-color: #4c5250;
  bottom: 0;
  color: rgb(109, 89, 53);
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 40vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.fadeout {
  animation: fadeout 4s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.iris {
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
